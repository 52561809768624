
import { Component, Vue, Watch } from 'vue-property-decorator'
import { required, email, sameAs } from 'vuelidate/lib/validators'
import { ErrorsModel } from '@/core/models/ErrorsModel'
import { RegisterModel } from './models/RegisterModel'
import { namespace } from 'vuex-class'
const AuthStore = namespace('AuthStore')

import BaseButton from '@/components/base/BaseButton.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import BaseCheckbox from '@/components/forms/BaseCheckbox.vue'
import Field from '@/components/forms/Field.vue'
import Modal from '@/components/Modal.vue'

const validations = {
  registerModel: {
    name: { required },
    email: { required, email },
    phone: { required },
    terms: { required, sameAs: sameAs(() => true) },
  },
}

@Component({
  components: { FormGroup, Field, BaseButton, BaseCheckbox, Modal },
  metaInfo: {
    title: 'Регистрация',
  },
  validations,
})
export default class RegisterPage extends Vue {
  showModal = false
  modalButtonActive = false
  successMessage = ''
  errors: ErrorsModel | null = null
  registerModel: RegisterModel = new RegisterModel()
  terms = false

  @AuthStore.Action
  private register!: (data: RegisterModel) => Promise<RegisterModel | any>

  async submit(): Promise<any> {
    this.$v.$touch()

    if (this.$v.$invalid) {
      return false
    } else {
      let clone = Object.assign({}, this.registerModel)
      let phone = clone.phone

      if (typeof phone === 'string') {
        phone = phone.replace(/\D/g, '')
        clone.phone = +phone
      }

      const res = await this.register(clone)

      if (res.status === 200) {
        this.registerModel = new RegisterModel()
        this.$v.$reset()
        this.successMessage = res.data.message
        this.showModal = true
      }

      if (res.errors) {
        this.errors = res.errors
      }
    }
  }

  @Watch('showModal')
  onModalShow(val: boolean): void {
    if (val) {
      setTimeout(() => {
        this.modalButtonActive = true
      }, 7000)
    }
  }
}
